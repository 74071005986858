import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { Link } from 'react-router-dom';
import FavouriteIcon from '../ui/icons/FavouriteIcon';
import { NumberBadge } from '../ui/NumberBadge';
import { config } from '../../shop.config.custom';
import t from '@jetshop/intl';

export function FavouriteCount({ className, text = false, active = false }) {
  const { count } = useProductList();
  return (
    <Link to={config.routes.favourites.path} className={className}>
      <span className="icon-holder">
        {count > 0 && <NumberBadge text={count} />}
        <FavouriteIcon className="header-icon" active={active} />
      </span>
      {text && <span className="text">{t('Wishlist')}</span>}
    </Link>
  );
}
