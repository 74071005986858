import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import MobileCategories from './MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import { Link } from 'react-router-dom';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { ChannelSelectorDropdown } from '../ChannelSelector/ChannelSelector';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';
import { usePrimaryBlogPath } from '../../../Blog/hook/usePrimaryBlogPath';
import PackageIcon from '../../../ui/icons/PackageIcon';

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  padding-bottom: 100px;
  background: ${theme.colors.backgroundGrey};
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
  background: ${theme.colors.backgroundGrey};
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.secondary};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 0 0;
  border: none;
  height: 50px;
`;

const HeaderButton = styled('div')`
  padding: 10px 1rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.primaryLight2};
  h3 {
    color: ${theme.colors.white};
    font-weight: ${theme.weights.normal} !important;
  }
  &:first-child {
    h3 {
      text-decoration: underline;
    }
  }
  &:last-child {
    background: ${theme.colors.white};
    width: 50%;
  }
`;

const NavLists = styled('div')`
  margin-top: 5px;
  padding: 1rem ${theme.mobileHeader.nav.padding};
  background: ${theme.colors.white};
  ul {
    li {
      display: block;
      line-height: 42px;
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: ${theme.weights.semibold};
      font-size: ${theme.mobileHeader.nav.lv1.fontSize};
    }
  }
`;

const SocialList = styled('div')`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  h3 {
    width: calc(50% - 2.5px);
    background: white;
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  ul {
    width: calc(50% - 2.5px);
    display: flex;
    justify-content: space-between;
    li {
      background: white;
      display: flex;
      align-items: center;
      width: calc(50% - 2.5px);
      padding: 10px;
      justify-content: center;
      img {
        max-width: 40px;
        max-height: 100%;
        height: auto;
        width: auto;
      }
    }
  }
`;

const LoginWrapper = styled('div')`
  margin-top: 5px;
  background: ${theme.colors.backgroundGrey};
  display: flex;
  justify-content: space-between;
  > a {
    width: calc(50% - 2.5px);
    display: flex;
    align-items: center;
    background: white;
    justify-content: center;
    background: white;
    padding: 10px 2px;
    svg {
      font-size: 1.2rem;
      margin-right: 10px;
      color: ${theme.colors.primaryLight2};
    }
    h3,
    .text {
      font-weight: ${theme.weights.semibold};
      font-size: ${theme.mobileHeader.nav.lv1.fontSize};
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
  .icon-holder {
    position: relative;
    margin-right: 1rem;
    svg {
      margin-right: 0;
    }
    .number-badge {
    }
  }
`;

const ChannelSelectorWrapper = styled('div')`
  padding: 1rem ${theme.mobileHeader.nav.padding};
  margin-top: 5px;
  background: white;
  .channel-selector-button {
    height: 36px;
  }
  .channel-selector-dropdown {
    width: calc(100% + 2rem);
  }
`;

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => {
  const {
    mobilePageList,
    mobileMenuShopTitle,
    mobileMenuBlogTitle,
    mobileMenuCustomLink,
    mobileMenuCustomText,
    mobileSocialList
  } = useContext(SiteContentContext);
  const blogPath = usePrimaryBlogPath();
  return (
    <UIDrawer isOpen={isOpen} right size={320}>
      <MobileNavWrapper>
        <StyledDrawerHeader>
          <HeaderButton>
            <Link to="/" onClick={hideTarget}>
              <h3>{mobileMenuShopTitle}</h3>
            </Link>
          </HeaderButton>
          <HeaderButton>
            <Link to={blogPath} onClick={hideTarget}>
              <h3>{mobileMenuBlogTitle}</h3>
            </Link>
          </HeaderButton>
        </StyledDrawerHeader>
        <Scroll>
          <MobileCategories
            hideTarget={hideTarget}
            categories={categoryTreeRoots}
            categoryTreeRoots={categoryTreeRoots}
            isOpen={isOpen}
          />
          <LoginWrapper onClick={hideTarget}>
            <FavouriteCount text={true} active={false} />
            <Link
              to={mobileMenuCustomLink}
              onClick={hideTarget}
              style={{ display: 'flex' }}
            >
              <PackageIcon />
              <h3>{mobileMenuCustomText}</h3>
            </Link>
          </LoginWrapper>
          <NavLists onClick={hideTarget}>{mobilePageList}</NavLists>
          <SocialList onClick={hideTarget}>{mobileSocialList}</SocialList>
          <ChannelSelectorWrapper>
            <ChannelSelectorDropdown />
          </ChannelSelectorWrapper>
        </Scroll>
      </MobileNavWrapper>
    </UIDrawer>
  );
};
export default MobileNav;
