import React from 'react';
import Image from '@jetshop/ui/Image';

const Logo = ({ mobile = false, size = [400] }) => {
  let src = '/pub_images/original/logo.png';
  if (mobile) src = '/pub_images/original/logo-mobile.png';
  return <Image src={src} sizes={size} aspect={'300:100'} crop={false} />;
};

export default Logo;
