import header1 from './baysoir.woff2';
import header2 from './bigcaslon.woff2';

import { css } from 'linaria';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'baysoir';
        src: url(${header1}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'bigcaslon';
        src: url(${header2}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
  `;
}
