import { styled } from 'linaria/react';
import React from 'react';
import { useIntl } from '@jetshop/intl';
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { theme } from '../Theming/Theming';

const FieldErrorWrapper = styled('div')`
  ${theme.below.md} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const NewsletterWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;
  form,
  input {
    height: 100%;
    width: 100%;
  }
  form,
  .control-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    border: none;
    color: ${theme.colors.black};
    background: ${theme.colors.primaryLight3};
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme.colors.primaryLight2};
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  label {
    position: absolute;
    font-size: 0.75rem;
    left: calc(0.5rem + 1px);
    top: -6px;
    background: white;
    padding: 0 0.5rem;
  }
  button {
    color: ${theme.button.color};
    background: ${theme.colors.primaryLight2};
    text-transform: ${theme.button.textTransform};
    font-size: 1.2rem;
    ${theme.above.lg} {
      font-size: 1.5rem;
    }
    font-weight: ${theme.button.fontWeight};
    border: 0;
    width: 140px;
    padding: 0 1rem;
    outline: none;
    height: 100%;
    cursor: pointer;
    &:disabled {
      opacity: 0.9;
    }
  }
  > p {
    color: ${theme.colors.font};
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

function NewsletterField({
  newsletterInput,
  newsletterSubmit,
  newsletterThanks,
  newsletterAlready,
  newsletterError
}) {
  const {
    inputProps,
    submit,
    submitting,
    submitted,
    failed,
    errorStates: { alreadySubscribed, invalidEmail }
  } = useNewsletterSubscription({
    enabled: true
  });

  const t = useIntl();
  const disabled = inputProps.value.length === 0 || submitting;

  return (
    <FieldErrorWrapper className="newsletter-wrapper">
      <Error className="error">
        {alreadySubscribed && newsletterAlready}
        {invalidEmail || failed ? newsletterError : null}
      </Error>
      <NewsletterWrapper className="form-wrapper">
        {submitted ? (
          <p data-testid="newsletter-subscription-success">
            {newsletterThanks}
          </p>
        ) : (
          <form
            onSubmit={submit}
            disabled={disabled}
            className="control-wrapper"
          >
            <input
              type="email"
              id="newsletter"
              placeholder={newsletterInput}
              data-testid="newsletter-subscription-input"
              {...inputProps}
            />
            <button
              className="button"
              type="submit"
              disabled={disabled}
              data-testid="newsletter-subscription-submit"
            >
              {submitting ? t('Submitting…') : newsletterSubmit}
            </button>
          </form>
        )}
      </NewsletterWrapper>
    </FieldErrorWrapper>
  );
}

export default NewsletterField;
