import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import TypekitFont from './Util/Fonts/TypekitFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import routeQuery from './RouteQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import './Theming/globalStyles';
import { config } from '../shop.config.custom';
import { theme } from './Theming/Theming';
import SiteContent from './SiteContent/SiteContentProvider';
import CustomFont from './Util/Fonts/CustomFont';
import loadFontCss from '../fonts/loadFontCss';
import BlogRoute from './Blog/BlogRoute';
import SiteContentContext from './SiteContent/SiteContentContext';
import { useContext } from 'react';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);
export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

const Routes = () => {
  const { routes } = useShopConfig();
  const { blogs } = useContext(SiteContentContext);
  return (
    <Switch>
      {blogs?.map((blog, index) => {
        const url = blog?.category?.value?.primaryRoute?.path;
        return (
          <Route
            key={index}
            exact
            path={
              blog.menu.value === 'yes'
                ? [`${url}/:cat/:item`, `${url}/:cat`, url]
                : [`${url}/:item`, url]
            }
          >
            <BlogRoute blog={blog} />
          </Route>
        );
      })}
      <Route exact path="/">
        <LoadableStartPage />
      </Route>
      <Route path={config.routes.favourites.path}>
        <Favourites />
      </Route>
      <Route path={routes.search.path}>
        <LoadableSearchPage />
      </Route>
      <Route path={routes.signup.path}>
        <LoadableSignUpPage />
      </Route>
      <Route path={routes.login.path}>
        <LogInPage />
      </Route>
      <Route path={routes.logout.path}>
        <LogOutPage />
      </Route>
      <Route path={routes.stores.path}>
        <StoreLocator />
      </Route>
      <Route path={`${routes.store.path}/:id`}>
        <Store />
      </Route>
      <Route path={routes.myPages.path}>
        <LoadableMyPages />
      </Route>
      <Route exact path={routes.forgotPassword.path}>
        <ForgotPassword />
      </Route>
      <Route path={`${routes.resetPassword.path}/:token`}>
        <ResetPassword />
      </Route>
      <Route
        path="/preview"
        render={props => (
          <PreviewRoute
            productQuery={ProductPreviewQuery}
            categoryQuery={CategoryPreviewQuery}
            pageQuery={PagePreviewQuery}
            productPage={LoadableProductPage}
            categoryPage={LoadableCategoryPage}
            contentPage={LoadableContentPage}
            StartPage={LoadableStartPage}
            {...props}
          />
        )}
      />
      <DynamicRoute
        routeQuery={routeQuery}
        productPage={LoadableProductPage}
        categoryPage={LoadableCategoryPage}
        contentPage={LoadableContentPage}
        notFoundPage={NotFound}
        LoadingPage={LoadingPage}
      />
    </Switch>
  );
};

function Shop() {
  return (
    <GenericError>
      <ModalProvider>
        <SiteContent>
          <Container>
            <LoadingBar />
            <CustomFont
              primaryFont={theme.font.secondary}
              secondaryFont={theme.font.third}
              injectCss={loadFontCss}
            />
            <TypekitFont primaryFont={theme.font.primary} id="fno1uaz" />
            <Helmet
              titleTemplate={`%s`}
              defaultTitle={config.settings.storeName}
            >
              <link
                rel="stylesheet"
                type="text/css"
                href="/se/stage/css/customer.css"
              />
            </Helmet>
            <ProductListProvider queries={productListQueries}>
              <PaginationProvider defaultProductsPerPage={36}>
                <Header />
                <Content>
                  <Routes />
                </Content>
                <Footer />
              </PaginationProvider>
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={[
                'sortOrderChange',
                'filterChange',
                'paginationChange'
              ]}
            />
          </Container>
        </SiteContent>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
