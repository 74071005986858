export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter'
    },
    brand: {
      id: 306
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Home of ess',
    siteContentId: 67
  }
};
