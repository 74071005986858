import React from 'react';
import { useQuery } from 'react-apollo';
import popupRouteQuery from './popupRouteQuery.gql';
import Popup from '../Popup';
import TextContent from '../TextContent';
import { ContentRender } from '../../ContentRender/ContentRender';

const PopupRoute = ({ path, close }) => {
  const { data } = useQuery(popupRouteQuery, {
    variables: {
      path: path
    }
  });
  const content = data?.route?.object?.content;
  const contentData = data?.route?.object?.data;

  return (
    <Popup isOpen={true} closePopup={close}>
      <TextContent content={content} />
      <ContentRender data={contentData} />
    </Popup>
  );
};

export default PopupRoute;
