import { styled } from 'linaria/react';
import React, { useEffect, useState } from 'react';
import { NewsletterWrapper } from './NewsletterField';

const Wrapper = styled.div`
  .error {
    margin-top: 10px;
    color: #650e1b;
  }
`;

function CustomNewsletter({
  newsletterInput,
  newsletterSubmit,
  newsletterThanks,
  newsletterError,
  newsletterErrorInvalid
}) {
  const [email, setEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    if (submitted) {
      // POST request using fetch inside useEffect React hook
      const requestOptions = {
        method: 'GET'
      };
      fetch(
        `https://app.essgroup.se/api/signup/?sname=newsletter&email=${email}&oid=1&cid=54&cc=email`,
        requestOptions
      )
        .then(response => response.json())
        .then(data => {
          console.log('posting email');
          console.log(data);
          setMessage(data?.body?.message || data?.message);
        })
        .catch(error => {
          setMessage('error');
          console.error('error posting email', error);
        });
      setSubmitted(false);
    }
  }, [email, submitted]);

  function validEmail() {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(validRegex);
  }

  return (
    <Wrapper className="newsletter-wrapper">
      <NewsletterWrapper className="form-wrapper">
        {message === 'success' ? (
          <p data-testid="newsletter-subscription-success">
            {newsletterThanks}
          </p>
        ) : (
          <>
            <div className="control-wrapper">
              <input
                type="email"
                id="newsletter"
                placeholder={newsletterInput}
                onChange={event => {
                  setMessage(false);
                  setEmail(event.target.value);
                }}
              />
              <button
                className="button"
                type="submit"
                data-testid="newsletter-subscription-submit"
                onClick={() => {
                  if (email && validEmail()) setSubmitted(true);
                  else setMessage('invalid');
                }}
              >
                {newsletterSubmit}
              </button>
            </div>
          </>
        )}
      </NewsletterWrapper>
      {message === 'error' && <div className="error">{newsletterError}</div>}
      {message === 'Invalid arguments.' && (
        <div className="error">{newsletterErrorInvalid}</div>
      )}
      {message === 'invalid' && (
        <div className="error">{newsletterErrorInvalid}</div>
      )}
    </Wrapper>
  );
}

export default CustomNewsletter;
