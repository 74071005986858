import React from 'react';
import ImageComponent from './ImageComponent';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { Above } from '@jetshop/ui/Breakpoints';

const HeroWrapper = styled.div`
  [data-flight-image-container] {
    padding-bottom: 0 !important;
    img {
      position: static !important;
      height: calc(100vh - ${theme.header.height.md + 'px'}) !important;
      ${theme.above.lg} {
        height: calc(100vh - ${theme.header.height.lg + 'px'}) !important;
      }
      object-fit: cover;
      opacity: 1;
      transition: opacity 500ms ease 0s;
    }
    &[data-flight-image-loaded='true'] [data-flight-image-placeholder] {
      display: none;
    }
  }
`;

export const Hero = ({ data, index }) => {
  return (
    <Above breakpoint="lg">
      {matches => {
        return (
          <HeroWrapper>
            <ImageComponent
              data={data}
              columns={1}
              aspect={matches ? '2:1' : '1:1'}
              key={index}
              sizes={[1, 1, 1, 1]}
              crop={true}
            />
          </HeroWrapper>
        );
      }}
    </Above>
  );
};
