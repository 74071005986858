import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Caret } from '../../../../svg/Caret.svg';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import { ButtonUI } from '../../../ui/ButtonUI';
import CheckIcon from '../../../ui/icons/CheckIcon';

const itemStyle = `
  padding: 0 0;
  font-size: 1rem;
  height: 30px;
  display: flex;
  align-items: center;
  ${theme.above.lg}{
    height: 36px;
  }
  &:hover {
    color: ${theme.colors.font};
  }
  &.active {
    font-weight: ${theme.weights.bold};
    p {
      font-weight: ${theme.weights.bold};
    }
  }
`;

export const ChannelSelectorWrapper = styled('div')`
  display: flex;
  height: 100%;
  background: transparent;
  position: relative;
  button {
    font-weight: 400;
  }
  .channel-selector-button {
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0 !important;
    justify-content: space-between;
    .code {
      margin-right: 5px;
    }
    svg {
      transition: all 200ms;
      margin-left: 10px;
    }
    span {
      font-family: ${theme.button.fontFamily};
      font-weight: ${theme.button.fontWeight};
      text-transform: ${theme.button.textTransform};
      letter-spacing: ${theme.button.letterSpacing};
    }
  }
  &[data-open='true'] {
    .channel-selector-button {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  .channel-selector-dropdown {
    position: absolute;
    top: 100%;
    left: -1rem;
    width: 100%;
    min-width: 240px;
    border-top: none;
    background: ${theme.colors.white};
    box-shadow: 1px 1px 5px #0000004f;
    z-index: 3;
    padding: 1rem;
  }
  .list {
    display: flex;
    flex-direction: column;
  }
  .channel-list {
    .channel {
      ${itemStyle};
    }
  }
  .options-header {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
  .channel-options {
    display: flex;
    margin-top: 5px;
    justify-content: space-around;
    .channel-option {
      width: 49%;
      padding: 5px 0;
      .options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .option {
          display: flex;
          align-items: center;
          .icon {
            width: 15px;
            margin-right: 5px;
            border-radius: 50%;

            background: #ffffff;
            border: 1px solid #ddd;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 0.7rem;
              color: ${theme.colors.black};
            }
          }
        }
      }
    }
  }
  .channel-confirm {
    margin-top: 1rem;
    button {
      width: 100%;
      height: 40px;
    }
  }
`;

const ChannelOptions = ({ options, selected, clickFunction }) => {
  const sortOptions = [
    ...options.filter(opt => opt.isDefault),
    ...options.filter(opt => !opt.isDefault)
  ];
  return (
    <div className="channel-option">
      <div className="options">
        {sortOptions.map((option, index) => (
          <button
            key={index}
            onClick={() => clickFunction(option)}
            className={`option ${selected === option ? 'active' : ''}`}
          >
            <span className="icon">
              {selected === option ? <CheckIcon /> : null}
            </span>
            {option.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export const ChannelSelectorDropdown = () => {
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );

  const isDev =
    typeof window !== 'undefined' &&
    (window.location.host.search('dev.jetshop.se') !== -1 ||
      window.location.host.search('localhost') !== -1);

  //FILTER OUT DEV CHANNELS
  const channelsFiltered = isDev
    ? channels
    : channels.filter(
        channel =>
          channel.url.search('jetshop') === -1 &&
          channel.name.search('B2B') === -1
      );

  const [dropdown, setDropdown] = useState(false);

  function prepareChannel(channel) {
    return {
      ...channel,
      country: channel.countries[0],
      currency: channel.currencies.filter(lang => lang.isDefault)[0],
      language: channel.languages.filter(lang => lang.isDefault)[0]
    };
  }

  const [activeChannel, setActiveChannel] = useState(selectedChannel);

  const wrapperRef = React.createRef();

  useEffect(() => {
    //CLOSE OUTSIDE LISTENER
    const listener = e => {
      if (!wrapperRef.current) return;
      if (!wrapperRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [setDropdown, wrapperRef]);

  return (
    <ChannelSelectorWrapper
      className="channel-selector-wrapper"
      ref={wrapperRef}
      data-open={dropdown}
    >
      <button
        className="channel-selector-button"
        onClick={() => setDropdown(!dropdown)}
      >
        <span className="name">{selectedChannel.name}</span>
        <Caret />
      </button>
      {dropdown && (
        <div className="channel-selector-dropdown">
          <div className="channel-list list">
            {channelsFiltered.map((channel, index) => {
              return (
                <button
                  className={`channel ${
                    activeChannel.id === channel.id ? 'active' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    setActiveChannel(prepareChannel(channel));
                  }}
                >
                  {channel.name}
                </button>
              );
            })}
          </div>
          <p className="options-header">Currency & Language</p>
          <div className="channel-options">
            <ChannelOptions
              options={activeChannel.currencies}
              selected={activeChannel.currency}
              clickFunction={option => {
                setActiveChannel({
                  ...activeChannel,
                  currency: option
                });
              }}
              name="Currency"
            />
            <ChannelOptions
              options={activeChannel.languages}
              selected={activeChannel.language}
              clickFunction={option => {
                setActiveChannel({
                  ...activeChannel,
                  language: option
                });
              }}
              name="Language"
            />
          </div>
          <div className="channel-confirm">
            <ButtonUI
              className="buy"
              onClick={() => {
                if (selectedChannel !== activeChannel)
                  updateChannel(activeChannel);
                else setDropdown(false);
              }}
            >
              Confirm
            </ButtonUI>
          </div>
        </div>
      )}
    </ChannelSelectorWrapper>
  );
};
