import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import DataProvider from './Categories/CategoryProvider';
import TopBar from './TopBar/TopBar';
import DesktopHeader from './Desktop/DesktopHeader';
import DesktopNav from './Desktop/DesktopNav';
import MobileHeader from './Mobile/MobileHeader';
import MobileNav from './Mobile/MobileNav';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theming/Theming';
import { Notifications } from '../Notifications';

const DesktopHeaderWrapper = styled('header')`
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  a {
    text-decoration: none;
    &:hover {
    }
  }
  ${theme.below.sm} {
  }
`;

const DesktopHeaderSpacer = styled('div')`
  height: ${theme.header.height.lg + 'px'};
  ${theme.only.md} {
    height: ${theme.header.height.md + 'px'};
  }
`;

const MobileHeaderWrapper = styled('header')`
  position: fixed;
  z-index: 104;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  a {
    text-decoration: none;
    &:hover {
    }
  }
  ${theme.below.sm} {
  }
`;
const MobileHeaderSpacer = styled('div')`
  height: ${theme.header.height.sm + 'px'};
`;

class Header extends Component {
  state = {
    searchOpen: false,
    scrolling: false
  };

  setSearch = searchOpen => this.setState({ searchOpen });
  componentDidMount = function() {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = function() {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = event => {
    if (window.scrollY < theme.header.scrollBreak && this.state.scrolling) {
      this.setState({ scrolling: false });
    } else if (
      window.scrollY > theme.header.scrollBreak &&
      !this.state.scrolling
    ) {
      this.setState({ scrolling: true });
    }
  };

  render() {
    const { categoryTreeRoots, categories } = this.props;
    const { searchOpen } = this.state;
    const { setSearch } = this;
    return (
      <React.Fragment>
        <Below breakpoint="lg">
          {matches =>
            matches ? (
              <Fragment>
                <MobileHeaderSpacer data-scrolling={this.state.scrolling} />
                <MobileHeaderWrapper id="main-header">
                  <MobileHeader
                    searchOpen={searchOpen}
                    setSearch={setSearch}
                    scrolling={this.state.scrolling}
                  />
                </MobileHeaderWrapper>
                <DrawerTarget id="menuDrawer" showCover={true}>
                  {({ isOpen, hideTarget }) => (
                    <MobileNav
                      categories={categories}
                      categoryTreeRoots={categoryTreeRoots}
                      onClick={hideTarget}
                      isOpen={isOpen}
                      hideTarget={hideTarget}
                    />
                  )}
                </DrawerTarget>
              </Fragment>
            ) : (
              <Fragment>
                <DesktopHeaderSpacer data-scrolling={this.state.scrolling} />
                <DesktopHeaderWrapper id="main-header">
                  <TopBar scrolling={this.state.scrolling} />
                  <DesktopHeader
                    scrolling={this.state.scrolling}
                    searchOpen={searchOpen}
                    setSearch={setSearch}
                  ></DesktopHeader>
                  <DesktopNav
                    scrolling={this.state.scrolling}
                    categoryTreeRoots={categoryTreeRoots}
                  />
                </DesktopHeaderWrapper>
              </Fragment>
            )
          }
        </Below>
        <CartFlyout />
        <Notifications />
      </React.Fragment>
    );
  }
}

const HeaderComponent = () => (
  <DataProvider>{props => <Header {...props} />}</DataProvider>
);

export default HeaderComponent;
